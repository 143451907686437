import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { find } from "lodash"

import MetaTitle from "src/components/MetaTitle"
import Layout from "src/components/Layout"
import { Box, Text, A, Year, theme } from "src/style"

const Grid = styled(Box).attrs({
  pt: "13em",
})`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-basis: 30%;

  ${theme.mediaQueries.small} {
    margin-left: 0;
    padding-left: 0;
  }

  ${theme.mediaQueries.medium} {
    padding-left: 5%;
    padding-top: 16em;
    padding-left: 0.34375em;
    margin-left: 0;
  }

  ${theme.mediaQueries.large} {
    padding-left: calc((100vw - 1300px) / 2 - 4em);
    padding-right: 1em;
    padding-top: 15em;
  }

  ${theme.mediaQueries.xlarge} {
    padding-left: calc((100vw - 1500px) / 2 - 4em);
  }
`

const Client = styled(Box).attrs({
  mb: 9,
})`
  min-width: 0;
  flex-basis: 94%;

  ${theme.mediaQueries.small} {
    flex-basis: 40%;
    margin-left: 1.8em;
  }

  ${theme.mediaQueries.medium} {
    flex-basis: 30%;
    margin-left: 2em;
  }

  ${theme.mediaQueries.fifteeninches} {
    flex-basis: 22%;
  }
`

const ClientName = styled(Text).attrs({
  fontFamily: "boldSans",
  fontSize: "sm",
  mb: 4,
})`
  margin-left: 2.5em;
  letter-spacing: 0.015em;
`

const Project = styled(Box).attrs({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  my: 4,
})``

const ProjectName = styled(Text).attrs({
  fontFamily: "serif",
})`
  flex-grow: 1;
  flex-basis: auto;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${theme.mediaQueries.medium} {
    font-size: 1.4rem;
  }

  ${theme.mediaQueries.large} {
    font-size: 1.2rem;
  }
`

const findProjectsByClientSlug = (slug, groups) => {
  return find(groups, group => {
    return group.clientSlug === slug
  })
}

export default ({
  data: {
    work: { groups },
    clients: {
      data: { clients },
    },
  },
}) => {
  return (
    <Layout themeName="dark">
      <MetaTitle title="Work" />
      <Grid>
        {clients.map(({ client }) => {
          if (!client || !client.document || client.document.length === 0) {
            return null
          }

          const clientName = client.document[0].data.name.text
          const projects = findProjectsByClientSlug(client.slug, groups)
          return (
            <Client>
              <ClientName>{clientName}</ClientName>
              {projects &&
                projects.nodes.map(project => {
                  const title =
                    project.data.short_title && project.data.short_title.text
                      ? project.data.short_title
                      : project.data.title

                  return (
                    <Project>
                      {project.data.label && project.data.label.text && (
                        <Year px={3} mr={5}>
                          {project.data.label.text}
                        </Year>
                      )}
                      {!project.data.label ||
                        (!project.data.label.text && (
                          <Year px={3} mr={5} style={{ opacity: 0 }}>
                            NEW
                          </Year>
                        ))}
                      <ProjectName>
                        <A to={`/work/${project.uid}`}>{title.text}</A>
                      </ProjectName>
                    </Project>
                  )
                })}
            </Client>
          )
        })}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query WorkClientsPageQuery {
    work: allPrismicProject {
      groups: group(field: data___client___slug) {
        pageInfo {
          perPage
        }
        clientSlug: fieldValue
        nodes {
          uid
          data {
            label {
              text
            }
            short_title {
              html
              text
            }
            title {
              html
              text
            }
          }
        }
      }
    }
    clients: prismicClientGrid(slugs: { eq: "clients" }) {
      data {
        clients {
          client {
            slug
            document {
              data {
                name {
                  html
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
